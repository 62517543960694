import { Option } from "../../components/UI-Elements/Form/Select";

export const instagramLink = "https://www.instagram.com/einfach_crypto/";
export const facebookLink = "https://www.facebook.com/einfachcrypto";
export const twitterProfileLink = "https://twitter.com/einfachcrypto";
export const rocksMail = "contact@mathias.rocks";
export const mathiasRocks = "https://mathias.rocks";

// CookieName: also check gatsby-config.js
export const consentCookieName = "gatsby-gdpr-google-analytics";

// Used for daily price info
export const coins: Option[] = [
  {
    value: "bitcoin",
    label: "Bitcoin",
  },
  {
    value: "ethereum",
    label: "Ethereum",
  },
  {
    value: "binancecoin",
    label: "Binance Coin",
  },
  {
    value: "solana",
    label: "Solana",
  },
  {
    value: "cardano",
    label: "Cardano",
  },
  {
    value: "ripple",
    label: "XRP",
  },
  {
    value: "polkadot",
    label: "Polkadot",
  },
  {
    value: "terra-luna",
    label: "Terra Luna",
  },
  {
    value: "avalanche-2",
    label: "Avalanche",
  },
  {
    value: "dogecoin",
    label: "Dogecoin",
  },
  {
    value: "shiba-inu",
    label: "Shiba Inu",
  },
];

export const currencies: Option[] = [
  {
    value: "usd",
    label: "Dollar",
  },
  {
    value: "eur",
    label: "Euro",
  },
];
