exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-charts-defi-tsx": () => import("./../../../src/pages/charts/defi.tsx" /* webpackChunkName: "component---src-pages-charts-defi-tsx" */),
  "component---src-pages-charts-nft-tsx": () => import("./../../../src/pages/charts/nft.tsx" /* webpackChunkName: "component---src-pages-charts-nft-tsx" */),
  "component---src-pages-charts-smart-contracts-tsx": () => import("./../../../src/pages/charts/smart-contracts.tsx" /* webpackChunkName: "component---src-pages-charts-smart-contracts-tsx" */),
  "component---src-pages-coins-tsx": () => import("./../../../src/pages/coins.tsx" /* webpackChunkName: "component---src-pages-coins-tsx" */),
  "component---src-pages-dashboard-bitcoin-dashboard-tsx": () => import("./../../../src/pages/dashboard/bitcoin-dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-bitcoin-dashboard-tsx" */),
  "component---src-pages-dashboard-google-trends-tsx": () => import("./../../../src/pages/dashboard/google-trends.tsx" /* webpackChunkName: "component---src-pages-dashboard-google-trends-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-market-dashboard-tsx": () => import("./../../../src/pages/dashboard/market-dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-market-dashboard-tsx" */),
  "component---src-pages-dashboard-top-250-kryptowaehrungen-tsx": () => import("./../../../src/pages/dashboard/top250-kryptowaehrungen.tsx" /* webpackChunkName: "component---src-pages-dashboard-top-250-kryptowaehrungen-tsx" */),
  "component---src-pages-ig-boersen-tsx": () => import("./../../../src/pages/ig/boersen.tsx" /* webpackChunkName: "component---src-pages-ig-boersen-tsx" */),
  "component---src-pages-ig-empfehlungen-tsx": () => import("./../../../src/pages/ig/empfehlungen.tsx" /* webpackChunkName: "component---src-pages-ig-empfehlungen-tsx" */),
  "component---src-pages-ig-index-tsx": () => import("./../../../src/pages/ig/index.tsx" /* webpackChunkName: "component---src-pages-ig-index-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagram-tsx": () => import("./../../../src/pages/instagram.tsx" /* webpackChunkName: "component---src-pages-instagram-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-tools-exchange-comparison-tsx": () => import("./../../../src/pages/tools/exchange-comparison.tsx" /* webpackChunkName: "component---src-pages-tools-exchange-comparison-tsx" */),
  "component---src-pages-tools-follower-tsx": () => import("./../../../src/pages/tools/follower.tsx" /* webpackChunkName: "component---src-pages-tools-follower-tsx" */),
  "component---src-pages-tools-price-daily-tsx": () => import("./../../../src/pages/tools/price-daily.tsx" /* webpackChunkName: "component---src-pages-tools-price-daily-tsx" */),
  "component---src-pages-wissen-buecher-tsx": () => import("./../../../src/pages/wissen/buecher.tsx" /* webpackChunkName: "component---src-pages-wissen-buecher-tsx" */),
  "component---src-pages-wissen-index-tsx": () => import("./../../../src/pages/wissen/index.tsx" /* webpackChunkName: "component---src-pages-wissen-index-tsx" */),
  "component---src-pages-wissen-kalender-tsx": () => import("./../../../src/pages/wissen/kalender.tsx" /* webpackChunkName: "component---src-pages-wissen-kalender-tsx" */),
  "component---src-pages-wissen-kategorien-cex-tsx": () => import("./../../../src/pages/wissen/kategorien/cex.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-cex-tsx" */),
  "component---src-pages-wissen-kategorien-defi-tsx": () => import("./../../../src/pages/wissen/kategorien/defi.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-defi-tsx" */),
  "component---src-pages-wissen-kategorien-dex-tsx": () => import("./../../../src/pages/wissen/kategorien/dex.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-dex-tsx" */),
  "component---src-pages-wissen-kategorien-digitales-gold-tsx": () => import("./../../../src/pages/wissen/kategorien/digitales-gold.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-digitales-gold-tsx" */),
  "component---src-pages-wissen-kategorien-gaming-tsx": () => import("./../../../src/pages/wissen/kategorien/gaming.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-gaming-tsx" */),
  "component---src-pages-wissen-kategorien-index-tsx": () => import("./../../../src/pages/wissen/kategorien/index.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-index-tsx" */),
  "component---src-pages-wissen-kategorien-meme-coins-tsx": () => import("./../../../src/pages/wissen/kategorien/meme-coins.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-meme-coins-tsx" */),
  "component---src-pages-wissen-kategorien-nft-tsx": () => import("./../../../src/pages/wissen/kategorien/nft.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-nft-tsx" */),
  "component---src-pages-wissen-kategorien-smart-contracts-tsx": () => import("./../../../src/pages/wissen/kategorien/smart-contracts.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-smart-contracts-tsx" */),
  "component---src-pages-wissen-kategorien-stablecoins-tsx": () => import("./../../../src/pages/wissen/kategorien/stablecoins.tsx" /* webpackChunkName: "component---src-pages-wissen-kategorien-stablecoins-tsx" */),
  "component---src-pages-wissen-meine-links-tsx": () => import("./../../../src/pages/wissen/meine-links.tsx" /* webpackChunkName: "component---src-pages-wissen-meine-links-tsx" */),
  "component---src-pages-wissen-seiten-und-buecher-tsx": () => import("./../../../src/pages/wissen/seiten-und-buecher.tsx" /* webpackChunkName: "component---src-pages-wissen-seiten-und-buecher-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-coin-page-tsx": () => import("./../../../src/templates/coin-page.tsx" /* webpackChunkName: "component---src-templates-coin-page-tsx" */)
}

