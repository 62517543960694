/* eslint-disable @typescript-eslint/no-empty-function */
import { consentCookieName } from "@common/config/config";
import React, { createContext, useContext, useState } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import { useCookie } from "react-use";

type ContextValue = {
  consent: boolean;
};
type AppContext = {
  value: ContextValue
  setCookieConsent: (value: boolean) => void;
};

const initialContext: AppContext = {
  value: {
    consent: Boolean(getCookieConsentValue(consentCookieName)),
  },
  setCookieConsent: () => {},
};
const AppContext = createContext<AppContext>(initialContext);

export function useAppContext() {
  return useContext(AppContext);
}
type Props = {
  children: React.ReactNode;
};

const AppContextProvider: React.FC<Props> = ({ children,  }) => {
  const [value, setValue] = useState(initialContext.value);
  const [, updateCookie, resetCookie] = useCookie(consentCookieName);

  const setCookieConsent = (consent: boolean) => {
    setValue({ ...value, consent });
    if (consent) {
      updateCookie("true", { sameSite: "lax" });
    } else { 
      resetCookie()
    }
  }

  const appContext: AppContext = {
    value,
    setCookieConsent,
  };

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
