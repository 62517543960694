import dayjs from "dayjs";
import AppContextProvider from "@components/Core/Contexts/AppContext";
import React from "react";
import "./src/styles/global.css";
import "./src/styles/global.less";
import "dayjs/locale/de";

dayjs.locale("de");

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>{element}</AppContextProvider>
);
